<template>
  <component :is="descriptionComponent"
             :notification="notification"/>
</template>

<script>
const lookup = {
  MonitorOnline: require('./Descriptions/MonitorOnline.vue').default,
  MonitorOffline: require('./Descriptions/MonitorOffline.vue').default,
  SSLCertificateExpired: require('./Descriptions/SSLCertificateExpired').default,
  SSLCertificateExpiresSoon: require('./Descriptions/SSLCertificateExpiresSoon').default,
  SSLCertificateDownloadFailed: require('./Descriptions/SSLCertificateDownloadFailed').default,
  MonitorOfflinePaused: require('./Descriptions/MonitorOfflinePaused').default,
  InactiveUserMonitorsPaused: require('./Descriptions/InactiveUserMonitorsPaused').default
}

export default {
  props: {
    notification: {
      required: true,
      type: Object
    }
  },

  computed: {
    descriptionComponent () {
      return lookup[this.notification.type]
    }
  }
}
</script>
