<template>
  <div>
    Your monitors have been automatically paused due to inactivity on your account. You can resume them at any time.
  </div>
</template>

<script>
import common from './common.js'

export default {
  mixins: [
    common
  ]
}
</script>
